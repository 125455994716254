"use strict";

require("jquery");
require("jquery-ui-dist/jquery-ui.js");
window.$ = $;
window.jQuery = $;

var rubyApp = require('./index');

var RubyComponent = require('@rubyapps/ruby-component');
var RubyComponentRubyApp = require('@rubyapps/ruby-component-ruby-app-simple');

rubyApp.init({
    rootComponent: RubyComponent.createElement(RubyComponentRubyApp)
}).then(function (rubyApp) {});